import React from 'react';
import { 
    Card, CardTitle, CardBody, CardLink,
    UncontrolledTooltip
} from 'reactstrap';
import './links.css';
import TagList from './tags';


function LinkCard({ link }){

    let {
        _id,
        title,
        tags
    } = link;

    return (
        <Card className="margin-tb-10px link-card">

            <CardTitle className="link-title">
                <CardLink 
                    id={`link-card-${_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/links/${_id}/view`}>
                        {title.length >= 40 ? `${title.substring(0, 40)}...` : title}
                </CardLink>
                <UncontrolledTooltip 
                    placement="auto" 
                    target={`link-card-${_id}`}
                    delay={350}
                    hideArrow={true}>
                    {title}
                </UncontrolledTooltip>
            </CardTitle>

            <CardBody className="link-body">
                <TagList tags={tags} />
            </CardBody>

        </Card>
    )
}

export default function LinkCards({ links }){

    if(typeof links === 'undefined'){
        return null;
    }

    return links.map((link, l) => <LinkCard link={link} key={l} />)
}
