import React, { Component } from 'react';

import './Tag.css';

import { 
    Container, 
    Row, 
    Col,
    Card,
    CardHeader,
    CardBody,
    Badge
} from 'reactstrap';

class Tag extends Component {

    constructor(props){
        super(props)

        this.state = {
            links: []
        }

        this.loadLinks = this.loadLinks.bind(this)
    }

    componentDidMount(){
        this.loadLinks()
    }

    loadLinks(){

        let {
            tag,
            all
        } = this.props.match.params;

        fetch(`${process.env.REACT_APP_BASE}/links-for-tag/${tag}/${all}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(res =>{
            let links = res
            this.setState({links})
        })
        .catch(err =>{
            console.log(err)
        })
    }

    renderLinks(links){
        return links.map((link, l) =>{
            let url = link.link;
            return (
                <Card bg="dark" text="white" key={l} className="link-item">
                    <CardHeader>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                        {link.title} 
                    </a>
                    </CardHeader>
                    <CardBody>
                        {link.tags.map((tag, t) => 
                            <Badge 
                                key={t} 
                                color="dark"
                                className="margin-r-5px">
                                {tag}
                            </Badge>
                        )}
                    </CardBody>
                </Card>
            )
        })
    }

    render(){

        let {
            links 
        } = this.state;


        return (
            <Container>
                <Row className="page-title">
                    <Col md={2}></Col>
                    <Col md={8} className="text-center">
                        <h2 className="title">Links for {this.props.match.params.tag}</h2>
                    </Col>
                    <Col md={2}></Col>
                </Row>
                <Row className="page-content">
                    <Col md={2}></Col>
                    <Col md={8}>
                        { this.renderLinks(links) }
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>
        )
    }
}

export default Tag;