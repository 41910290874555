import React from 'react';
import { Redirect } from 'react-router-dom';
import { 
    Container, Row, Col,
    Input, Button, Form, FormGroup,
    Label, Spinner
} from 'reactstrap';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import './Links.css';
import CustomEditor from './CustomEditor.js';

class Links extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            editorState: EditorState.createEmpty(),
            form: this.emptyForm(),
            loading: false,
            redirect: false,
            toPage: ''
        }

        this.redirectBtnClicked = this.redirectBtnClicked.bind(this);
        this.isEditMode = this.isEditMode.bind(this);
        this.submitForm = this.submitForm.bind(this);
        this.titleChanged = this.titleChanged.bind(this);
        this.editorChanged = this.editorChanged.bind(this);
        this.addLink = this.addLink.bind(this);
    }

    redirectBtnClicked(toPage){

        if(toPage === '#'){
            return;
        }

        this.setState({ redirect: true, toPage})
    }

    isEditMode(){
        return typeof this.props.match.params.id !== 'undefined';
    }

    componentDidMount(){

        if(!this.isEditMode()){
            return;
        }

        let {
            id
        } = this.props.match.params;

        fetch(`${process.env.REACT_APP_BASE}/links/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(res =>{

            let link = res.data;

            if(link.notes === null){
                this.setState({form: link})
            }else{

                let notes_json =  JSON.parse(link.notes);
                let contentState = convertFromRaw(notes_json);
    
                const editorState = EditorState.push(this.state.editorState, contentState);
                this.setState({form: link, editorState})
            }
        })
        .catch(err =>{
            console.log(err)
        })

    }

    emptyForm(){
        return {
            title: '',
            links: [],
            tags: [],
            notes: '',
            comments: []
        }
    }

    titleChanged(e){
        let form = { ...this.state.form};
        form.title = e.target.value;
        this.setState({form})
    }

    editorChanged(editorState){
        this.setState({editorState});
    }

    textChanged(e){
        let form = { ...this.state.form};
        form.text = e.target.value;
        this.setState({form});
    }

    isFormValid(form){
        let {
            title,
            links,
            tags
        } = form;

        if(title.trim().length === 0){
            return false;
        }

        return true;
    }

    submitForm(e){
        e.preventDefault();

        let form = Object.assign({}, this.state.form);

        if(!this.isFormValid(form)){
            return;
        }

        this.setState({loading: true});

        let currentState = this.state.editorState.getCurrentContent();
        let content = convertToRaw(currentState);
        let contentHtml = stateToHTML(currentState);

        form.notes = JSON.stringify(content);
        form.notes_html = contentHtml;

        let body = {
            link: form
        }

        if(typeof form._id === 'undefined'){
            fetch(`${process.env.REACT_APP_BASE}/link`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            .then(res =>{

                this.setState({
                    form: this.emptyForm(),
                    editorState: EditorState.createEmpty(),
                    loading: false,
                    toPage: '/',
                    redirect: true
                });
            })
            .catch(err =>{
                console.log(err);
                this.setState({loading: false});
            }) 
        }else{
            fetch(`${process.env.REACT_APP_BASE}/links/${form._id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            .then(res =>{

                this.setState({
                    form: this.emptyForm(),
                    editorState: EditorState.createEmpty(),
                    loading: false,
                    toPage: '/',
                    redirect: true
                });
            })
            .catch(err =>{
                console.log(err);
                this.setState({loading: false});
            }) 
        }       
    }

    tagChanged(i, e){
        let tag = e.target.value;
        let form = { ...this.state.form };
        form.tags[i] = tag;
        this.setState({form});
    }

    addTag(){
        let form = { ...this.state.form};
        let tags = this.state.form.tags;

        if(tags.length === 3)
            return;

        tags.push('');
        this.setState({ form });   
    }

    removeTag(i){
        let form = { ...this.state.form };
        form.tags.splice(i, 1);
        this.setState({form});
    }    

    addLink(){
        let form = { ...this.state.form};
        let links = this.state.form.links;

        links.push('');
        this.setState({ form });
    }

    linkChange(i, e){
        let link = e.target.value;
        let form = { ...this.state.form };
        form.links[i] = link;
        this.setState({form});
    }

    removeLink(i){
        let form = { ...this.state.form };
        form.links.splice(i, 1);
        this.setState({form});
    }

    render(){

        let { 
            editorState, 
            form, 
            redirect, 
            toPage,
            loading
        } = this.state;

        let {
            title,
            links,
            tags
        } = form;

        let isThisEditMode = this.isEditMode();

        if(redirect){
            return <Redirect to={toPage} />;
        }

        return (
            <Container className="links-container">

                <Row>

                    <Col md={2}></Col>

                    <Col className="page-title">
                        { isThisEditMode ? <h2>Edit Link</h2> : <h2>New Link</h2>}
                    </Col>

                    <Col md={2}></Col>

                </Row>
                <Row>

                    <Col md={2}></Col>

                    <Col md={8}>
                        <Form onSubmit={this.submitForm}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="title">Title</Label>
                                        <Input type="text" name="title" id="title" onChange={this.titleChanged} value={title}  required/>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label>Link</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Button
                                                    type="button"
                                                    className= "btn"
                                                    onClick={this.addLink}>
                                                    +
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {
                                        links.map((link, l) =>(
                                            <Row key={l}>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Input                       
                                                            type="url" 
                                                            name="link" 
                                                            id={`link-${l}`}
                                                            onChange={this.linkChange.bind(this, l)}
                                                            value={link} 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <Button
                                                            type="button"
                                                            className="btn-danger"
                                                            onClick={this.removeLink.bind(this, l)}>
                                                            x
                                                        </Button>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        ))
                                    }

                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label> Content </Label>

                                        <CustomEditor
                                            editorChanged={this.editorChanged}
                                            editorState={editorState}
                                        />

                                    </FormGroup>                                
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="tags">Tags</Label>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                        <FormGroup>
                                            <Button type="button" onClick={this.addTag.bind(this)}>+</Button>
                                        </FormGroup>
                                        </Col>
                                    </Row>

 
                                    {tags.map((tag, t) => 
                                        <Row key={t}>

                                            <Col md={6}>
                                                <FormGroup >
                                                    <Input 
                                                        type="text" 
                                                        name="tags" 
                                                        id={`tags-${t}`}
                                                        onChange={this.tagChanged.bind(this, t)}
                                                        value={tag} 
                                                    />
                                                </FormGroup>                                            

                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Button
                                                        type="button"
                                                        className="btn-danger"
                                                        onClick={this.removeTag.bind(this, t)}>
                                                        x
                                                    </Button>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    )}
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Button 
                                            color="primary" 
                                            className="btn btn-block" 
                                            type="submit" 
                                            onClick={this.submitForm}
                                            disabled={loading}>
                                            Submit
                                            
                                        </Button>
                                        {
                                            loading && 
                                            <Spinner size="sm" color="primary" />
                                        }
                                        
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>

                    <Col md={2}></Col>

                </Row>
            </Container>
        )
    }
}

export default Links;