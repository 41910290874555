import React, { Component } from 'react';
import 'draft-js/dist/Draft.css';
import './App.css';
import Header from './Header';
import Home from './Home';
import Links from './Links';
import NoTagLinks from './NoTagLinks';
import LinksView from './LinksView';
import Tag from "./Tag";
import Search from './Search';
import { BrowserRouter as Router, Route} from "react-router-dom";

class App extends Component {
  render() {
    return (
      <div className="App"> 
        <Router>
          <div>
            <Header/>
            <Route exact path="/" component={Home} /> 
            <Route exact path="/links" component={Links} />
            <Route exact path="/links-no-tag" component={NoTagLinks} />
            <Route exact path="/links/:id/view" component={LinksView} />
            <Route exact path="/links/:id/edit" component={Links} />
            <Route exact path="/search" component={Search} />
            <Route path='/tags/:tag/:all' component={ Tag } />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
