import React  from 'react';

import { 
    Container, Row, Col, Badge
} from 'reactstrap';

import { Editor, EditorState, convertFromRaw } from "draft-js";


class LinksView extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            link: {}
        }

        this.loadLink = this.loadLink.bind(this);
    }

    componentDidMount(){
        this.loadLink();
    }

    loadLink() {

        let {
            id
        } = this.props.match.params;

        fetch(`${process.env.REACT_APP_BASE}/links/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(res =>{
            let link = res.data;
            link.notes_json = JSON.parse(link.notes);
            this.setState({link})
        })
        .catch(err =>{
            console.log(err)
        })

    }

    render() {

        let { link } = this.state;

        let {
            _id,
            title,
            tags,
            links,
            notes_json
        } = link;

        if(typeof _id === 'undefined'){
            return null;
        }

        let contentState;
        let editorState;
        let contentLoaded = false;
        let notesExist = false;

        if(typeof _id  === 'undefined'){
            contentState = null;
            editorState = null;
        }else{
            contentLoaded = true;
            notesExist = notes_json !== null;

            if(notesExist){
                contentState = convertFromRaw(notes_json);
                editorState = EditorState.createWithContent(contentState);
            }
        }

        return (

            <Container>
                <Row className="some-margin">

                    <Col md={2}></Col>

                    <Col md={8}>
                        <div className="text-center">
                            <h4>{title}</h4>
                        </div>

                        <div className="text-center">
                            <h6>
                                <a href={links[0]} target="_blank" rel="noopener noreferrer"> 
                                    {links.length > 0 ? 'source' : ''}
                                </a>
                            </h6>
                        </div>
                        <div className="text-center">
                            <a href={`/links/${_id}/edit`}> Edit </a>
                        </div>
                        <div className="text-center">
                            {contentLoaded && tags.map((tag, t) => 
                                <Badge 
                                    key={t} 
                                    color="dark"
                                    className="margin-r-5px">
                                    {tag}
                                </Badge>
                            )} 
                        </div>
                        <div className="mt-5">
                            {
                                contentLoaded && notesExist &&
                                <Editor editorState={editorState} readOnly={true} />
                            }
                        </div>
                    </Col>

                    <Col md={2}></Col>

                </Row>
            </Container>
        )
    }
}

export default LinksView;

