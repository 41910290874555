import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { 
    Container, Row, Col, 
    Button, Badge
} from 'reactstrap';
import './Home.css';

import LinkCards from './components/links';

function TagButton({ btnClick, n, title, link }){

    if(n === 0){
        return null;
    }

    return (
        <Button 
            className="dashboard-item"
            color="primary"
            onClick={(e)=> btnClick(link)}
            outline>
            {title} <Badge color="light">{n}</Badge>
        </Button>
    )
}

function Dashboard(props){

    let {
        totalLinks,
        noTagLinks,
        noNotesLinks,
        thisWeek
    } = props.data;

    return (

        <div>
            <div className="text-center">
                <h4> Links </h4>
            </div>

            <div className="dashboard-container">

                <TagButton 
                    btnClick={props.redirectBtnClicked} 
                    n={noTagLinks} 
                    title={"No Tags"}
                    link={"/links-no-tag"} />


                <TagButton 
                    btnClick={props.redirectBtnClicked} 
                    n={noNotesLinks} 
                    title={"No Notes"}
                    link={"#"}/>

                <TagButton 
                    btnClick={props.redirectBtnClicked} 
                    n={totalLinks} 
                    title={"Total"}
                    link={"#"}
                    />

            </div>

            <h5> This Week </h5>

            <LinkCards links={thisWeek} />

        </div>
    )
}

class Home extends Component {

    constructor(props) {
        super(props)

        this.state = {
            dashboard: {},
            redirect: false,
            toPage: ''
        }

        this.loadDashboard = this.loadDashboard.bind(this);
        this.redirectBtnClicked = this.redirectBtnClicked.bind(this);
    }

    componentDidMount() {
        this.loadDashboard()
    }

    loadDashboard() {
        fetch(`${process.env.REACT_APP_BASE}/links-dashboard`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(responseData =>{

            let { data } = responseData;
            this.setState({dashboard: data})
        })
    }

    redirectBtnClicked(toPage){

        if(toPage === '#'){
            return;
        }

        this.setState({ redirect: true, toPage})
    }

    render(){

        let {
            dashboard,
            redirect,
            toPage
        } = this.state;

        if(redirect){
            return <Redirect to={toPage} />;
        }

        return (
            <Container>

                <Row className="some-margin">
                    <Col>
                        <Dashboard data={dashboard} redirectBtnClicked={this.redirectBtnClicked}/>
                    </Col>
                </Row>

            </Container>
        )
    }
}

export default Home;