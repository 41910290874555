import React from 'react';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

function TagSingle({ tag }){

    return (
        <Link to={`/tags/${tag}/1`}>
            <Badge 
                color="dark"
                className="margin-r-5px">
                {tag}
            </Badge>
        </Link>   
    )
}

export default function TagList({ tags }){

    if(typeof tags === 'undefined'){
        return null;
    }

    return tags.map((tag, t) => <TagSingle tag={tag} key={t} />)
}