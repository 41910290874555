import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem
} from 'reactstrap';

class Header extends Component {

    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          isOpen: false
        };
      }
      toggle() {
        this.setState({
          isOpen: !this.state.isOpen
        });
      }

    render(){
        return (
            <div>
                <Navbar color="light" light expand="md">
                <Link className="navbar-brand navbar-light" to="/">htalat's knowledge base</Link>
                <NavbarToggler onClick={this.toggle} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav className="ml-auto" navbar>
                        <NavItem className="text-white">
                            <Link className="navbar-light .navbar-nav nav-link" to="/search">Search</Link>
                        </NavItem>
                        <NavItem className="text-white">
                            <Link className="navbar-light .navbar-nav nav-link" to="/links">Links</Link>
                        </NavItem>
                    </Nav>
                </Collapse>
                </Navbar>
            </div>
        )
    }
}

export default Header;