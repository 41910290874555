import React from 'react';
import { Container, Row } from 'reactstrap';
import LinkCards from './components/links';

class NoTagLinks extends React.Component {

    constructor(props){
        super(props)
        this.loadData = this.loadData.bind(this)
        this.state = {
            noTagLinks: {}
        }
    }

    componentWillMount(){
        this.loadData()
    }

    loadData(){
        fetch(`${process.env.REACT_APP_BASE}/links-no-tags`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(res => res.json())
        .then(({ data })=>{

            let { noTagLinks } = data;

            this.setState({noTagLinks})
        })        
    }

    render(){

        let {
            noTagLinks
        } = this.state;

        if(Object.keys(noTagLinks).length === 0){
            return null;
        }

        return (
            <Container>

                <Row className="some-margin">
                    <h3> Links with no tags</h3>
                </Row>

                <Row className="some-margin">
                    <div>
                        <LinkCards links={noTagLinks} />
                    </div>
                </Row>
            </Container>
        )
    }
}

export default NoTagLinks;