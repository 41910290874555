import React from 'react';
import { 
    Container, Row, Col,
    Input, Button,
    Form, FormGroup,
} from 'reactstrap';

import socketIOClient from "socket.io-client";

import './Search.css';
import LinkCards from './components/links';

function getCount(result){

    if(typeof result === 'undefined')
        return 0;

    let count = 0;
    let keys = Object.keys(result);

    if(keys.length === 0)
        return;

    for(let key of keys){
        count += result[key].length;
    }
    return count;
}

function SearchResults(props){

    let {        
        results
    } = props;

    let count = getCount(results);

    if(typeof count === 'undefined' || count === 0){
        return null;
    }

    return (
        <Col className="search-result-col" md={8}>
            <LinkCards links={results['link']} />
        </Col>
    )

}

function SearchBar(props){

    let {
        searchBtnClicked,
        searchTextOnChange
    } = props;

    return (
        <Col md={8} className="search-bar-col">
            <Form className="search-bar-form" inline onSubmit={searchBtnClicked} autoComplete="off">
                <FormGroup className="search-text-form-group" >
                    <Input
                        className="search-text"
                        type="search"
                        name="search"
                        id="search"
                        placeholder="search"
                        defaultValue=""
                        onChange={searchTextOnChange}
                    />
                </FormGroup>
                <FormGroup className="search-button-form-group">
                        <Button
                            className="search-button"
                            type="submit"
                        >
                            find it!
                        </Button>
                </FormGroup>
            </Form>
        </Col>        
    )
}

class Search extends React.Component {

    constructor(props){

        super(props);

        this.state = {
            searchResults: {},
            searchText: '',
        }

        this.searchBtnClicked = this.searchBtnClicked.bind(this);
        this.searchTextOnChange = this.searchTextOnChange.bind(this);
        this.searchText = this.searchText.bind(this);

        this.socket = socketIOClient(`${process.env.REACT_APP_HOST}`);
        this.socket.on('searchResult', (result)=>{
            this.setState({
                searchResults: result
            })
        })
    }

    searchText(text){
        this.socket.emit('search', {
            data: text
        })
    }

    searchTextOnChange(e) {
        let text = e.target.value;
        let newState = {
            searchText: text
        }

        if(text === ''){
            Object.assign(newState, {searchResults: []})
        }

        this.setState(newState);
        this.searchText(text)
    }

    searchBtnClicked(e) {
        e.preventDefault();
    }

    render(){

        let {
            searchResults
        } = this.state;

        return (
            <Container className="search-container">
                <Row className="search-bar-row">

                    <Col md={2}></Col>

                    <SearchBar
                        searchTextOnChange={this.searchTextOnChange}
                        searchBtnClicked={this.searchBtnClicked}
                    />

                    <Col md={2}></Col>

                </Row>

                <Row className="search-results-row">

                    <Col md={2}></Col>
    
                    <SearchResults 
                        results={searchResults}
                    />

                    <Col md={2}></Col>

                </Row>

            </Container>
        )
    }
}

export default Search;